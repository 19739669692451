import React from 'react'
import { Box } from '@mui/material'
import { Table } from 'antd'
import { fontSize } from '@mui/system'

const columns = [
  {
    key: 'Name',
    title: 'Name',
    dataIndex: 'Name',
  },
  {
    key: 'Purpose',
    title: 'Purpose',
    dataIndex: 'Purpose',
  },
  {
    key: 'Duration',
    title: 'Duration',
    dataIndex: 'Duration',
  },
  {
    key: 'Provider',
    title: 'Provider',
    dataIndex: 'Provider',
  },
  {
    key: 'Type',
    title: 'Type',
    dataIndex: 'Type',
  },
]
const data = [
  {
    Name: 'Essential Cookies',
  },
  {
    Name: 'EXPRESS_LANG',
    Purpose: 'To store language preferences',
    Duration: 'Session',
    Provider: 'iMile',
    Type: 'First party',
  },
  {
    Name: 'EXPRESS_ACCESS_TOKEN',
    Purpose: 'Request header bearer user identity authentication',
    Duration: 'Session',
    Provider: 'iMile',
    Type: 'First party',
  },
  {
    Name: 'USER_INFO',
    Purpose: 'To share user login information in different domain names',
    Duration: 'Session',
    Provider: 'iMile',
    Type: 'First party',
  },
  {
    Name: 'Performance cookies',
    Purpose: '',
    Duration: '',
    Provider: '',
    Type: '',
  },
  {
    Name: '_fbp',
    Purpose: 'To track and record user activity and behaviour on the website',
    Duration: '30 days',
    Provider: 'Facebook pixel',
    Type: 'Third party',
  },
  {
    Name: '_ga_JZB7SGNDNX',
    Purpose: 'To track and record user activity and behaviour on the website',
    Duration: '400 days',
    Provider: 'Google tag manager',
    Type: 'Third party',
  },
  {
    Name: '_ga_XM0HEHEK5N',
    Purpose: 'To track and record user activity and behaviour on the website',
    Duration: '400 days',
    Provider: 'Google tag manager',
    Type: 'Third party',
  },
  {
    Name: '_ga',
    Purpose: 'To track and record user activity and behaviour on the website',
    Duration: '60 days',
    Provider: 'Google tag manager',
    Type: 'Third party',
  },
  {
    Name: '_ga_TKCPHWF8FX',
    Purpose: 'To track and record user activity and behaviour on the website',
    Duration: '400 days',
    Provider: 'Google tag manager',
    Type: 'Third party',
  },
  {
    Name: 'Functional Cookies',
    Purpose: '',
    Duration: '',
    Provider: '',
    Type: '',
  },
  {
    Name: 'NID',
    Purpose: 'To play YouTube videos embedded on our website',
    Duration: '175 days',
    Provider: 'Youtube',
    Type: 'Third party',
  },
  {
    Name: 'IDE',
    Purpose: 'To play YouTube videos embedded on our website',
    Duration: '208 days',
    Provider: 'YouTube',
    Type: 'Third party',
  },
  {
    Name: 'Id',
    Purpose: 'To play YouTube videos embedded on our website',
    Duration: '399 days',
    Provider: 'YouTube',
    Type: 'Third party',
  },
  {
    Name: 'VISITOR_INFO1_LIVE',
    Purpose: 'To play YouTube videos embedded on our website',
    Duration: '172 days',
    Provider: 'YouTube',
    Type: 'Third party',
  },
  {
    Name: 'YSC',
    Purpose: 'To play YouTube videos embedded on our website',
    Duration: 'Session',
    Provider: 'YouTube',
    Type: 'Third party',
  },
  {
    Name: 'Advertising / tracking cookies',
    Purpose: '',
    Duration: '',
    Provider: '',
    Type: '',
  },
  {
    Name: 'X-AB',
    Purpose: `Snapchat tracks users' page viewing behaviour on websites. This can be used to analyse and optimize ad campaigns`,
    Duration: '16 hours',
    Provider: 'Snap Inc.',
    Type: 'Third party',
  },
  {
    Name: 'sc_at',
    Purpose: `Snapchat tracks users' page viewing behaviour on websites. This can be used to analyse and optimize ad campaigns`,
    Duration: '395d8h18m',
    Provider: 'Snap Inc.',
    Type: 'Third party',
  },
  {
    Name: '_scid',
    Purpose: `Snapchat tracks users' page viewing behaviour on websites. This can be used to analyse and optimize ad campaigns`,
    Duration: '395d8h18m',
    Provider: 'Snap Inc.',
    Type: 'Third party',
  },
  {
    Name: '_sctr',
    Purpose: `Snapchat tracks users' page viewing behaviour on websites. This can be used to analyse and optimize ad campaigns`,
    Duration: '395d8h18m',
    Provider: 'Snap Inc.',
    Type: 'Third party',
  },
  {
    Name: '_scid_r',
    Purpose: `Snapchat tracks users' page viewing behaviour on websites. This can be used to analyse and optimize ad campaigns`,
    Duration: '395d8h18m',
    Provider: 'Snap Inc.',
    Type: 'Third party',
  },
]

const arData = [
  {
    name: 'ملفات تعريف الارتباط الأساسية',
  },
  {
    Name: 'EXPRESS_LANG',
    Purpose: 'لتخزين تفضيلات اللغة',
    Duration: 'جلسة',
    Provider: 'ايمايل',
    Type: 'الطرف الأول',
  },
  {
    Name: 'EXPRESS_ACCESS_TOKEN',
    Purpose: 'طلب تَرويسَة المصادقة لمعرف المستخدم',
    Duration: 'جلسة',
    Provider: 'ايمايل',
    Type: 'الطرف الأول',
  },
  {
    Name: 'USER_INFO',
    Purpose: 'لمشاركة معلومات تسجيل الدخول للمستخدم عبر أسماء نطاقات مختلفة',
    Duration: 'جلسة',
    Provider: 'ايمايل',
    Type: 'الطرف الأول',
  },
  {
    Name: 'ملفات تعريف الارتباط الخاصة بالأداء',
    Purpose: '',
    Duration: '',
    Provider: '',
    Type: '',
  },
  {
    Name: '_fbp',
    Purpose: 'لتتبع وتسجيل نشاط وسلوك المستخدم على الموقع الإلكتروني',
    Duration: '30 يوم',
    Provider: 'بِكسل فيسبوك',
    Type: 'الطرف الثالث',
  },
  {
    Name: '_ga_JZB7SGNDNX',
    Purpose: 'لتتبع وتسجيل نشاط وسلوك المستخدم على الموقع الإلكتروني',
    Duration: '400 يوم',
    Provider: 'أداة إدارة العلامات من جوجل',
    Type: 'الطرف الثالث',
  },
  {
    Name: '_ga_XM0HEHEK5N',
    Purpose: 'لتتبع وتسجيل نشاط المستخدم وسلوكه على الموقع الإلكتروني',
    Duration: '400 يوم',
    Provider: 'أداة إدارة العلامات من جوجل',
    Type: 'الطرف الثالث',
  },
  {
    Name: '_ga',
    Purpose: 'لتتبع وتسجيل نشاط المستخدم وسلوكه على الموقع الإلكتروني',
    Duration: '60 يوم',
    Provider: 'أداة إدارة العلامات من جوجل',
    Type: 'الطرف الثالث',
  },
  {
    Name: '_ga_TKCPHWF8FX',
    Purpose: 'لتتبع وتسجيل نشاط المستخدم وسلوكه على الموقع الإلكتروني',
    Duration: '400 يوم',
    Provider: 'أداة إدارة العلامات من جوجل',
    Type: 'الطرف الثالث',
  },
  {
    Name: 'ملفات تعريف الارتباط الوظيفية',
    Purpose: '',
    Duration: '',
    Provider: '',
    Type: '',
  },
  {
    Name: 'NID',
    Purpose: 'لتشغيل مقاطع الفيديو من يوتيوب المدمجة على موقعنا الإلكتروني',
    Duration: '175 يوم',
    Provider: 'يوتيوب',
    Type: 'الطرف الثالث',
  },
  {
    Name: 'IDE',
    Purpose: 'لتشغيل مقاطع الفيديو من يوتيوب المدمجة على موقعنا الإلكتروني',
    Duration: '208 يوم',
    Provider: 'يوتيوب',
    Type: 'الطرف الثالث',
  },
  {
    Name: 'Id',
    Purpose: 'لتشغيل مقاطع الفيديو من يوتيوب المدمجة على موقعنا الإلكتروني',
    Duration: '399 يوم',
    Provider: 'يوتيوب',
    Type: 'الطرف الثالث',
  },
  {
    Name: 'VISITOR_INFO1_LIVE',
    Purpose: 'لتشغيل مقاطع الفيديو من يوتيوب المدمجة على موقعنا الإلكتروني',
    Duration: '172 يوم',
    Provider: 'يوتيوب',
    Type: 'الطرف الثالث',
  },
  {
    Name: 'YSC',
    Purpose: 'لتشغيل مقاطع الفيديو من يوتيوب المدمجة على موقعنا الإلكتروني',
    Duration: 'جلسة',
    Provider: 'يوتيوب',
    Type: 'الطرف الثالث',
  },
  {
    Name: 'ملفات تعريف الارتباط الخاصة بالإعلانات / التتبع',
    Purpose: '',
    Duration: '',
    Provider: '',
    Type: '',
  },
  {
    Name: 'X-AB',
    Purpose:
      'يتتبع تطبيق سناب شات سلوكيات مشاهدة صفحات المستخدمين على المواقع الإلكترونية. يمكن استخدام هذه البيانات لتحليل وتحسين حملات الإعلانات.',
    Duration: '16 ساعة',
    Provider: 'شركة سناب',
    Type: 'الطرف الثالث',
  },
  {
    Name: 'sc_at',
    Purpose:
      'يتتبع تطبيق سناب شات سلوكيات مشاهدة صفحات المستخدمين على المواقع الإلكترونية. يمكن استخدام هذه البيانات لتحليل وتحسين حملات الإعلانات.',
    Duration: '٣٩٥ يومًا و٨ ساعات و١٨ دقيقة',
    Provider: 'شركة سناب',
    Type: 'الطرف الثالث',
  },
  {
    Name: '_scid',
    Purpose:
      'يتتبع تطبيق سناب شات سلوكيات مشاهدة صفحات المستخدمين على المواقع الإلكترونية. يمكن استخدام هذه البيانات لتحليل وتحسين حملات الإعلانات.',
    Duration: '٣٩٥ يومًا و٨ ساعات و١٨ دقيقة',
    Provider: 'شركة سناب',
    Type: 'الطرف الثالث',
  },
  {
    Name: '_sctr',
    Purpose:
      'يتتبع تطبيق سناب شات سلوكيات مشاهدة صفحات المستخدمين على المواقع الإلكترونية. يمكن استخدام هذه البيانات لتحليل وتحسين حملات الإعلانات.',
    Duration: '٣٩٥ يومًا و٨ ساعات و١٨ دقيقة',
    Provider: 'شركة سناب',
    Type: 'الطرف الثالث',
  },
  {
    Name: '_scid_r',
    Purpose:
      'يتتبع تطبيق سناب شات سلوكيات مشاهدة صفحات المستخدمين على المواقع الإلكترونية. يمكن استخدام هذه البيانات لتحليل وتحسين حملات الإعلانات.',
    Duration: '٣٩٥ يومًا و٨ ساعات و١٨ دقيقة',
    Provider: 'شركة سناب',
    Type: 'الطرف الثالث',
  },
]

const arColumns = [
  {
    key: 'Name',
    title: 'الاسم',
    dataIndex: 'Name',
  },
  {
    key: 'Purpose',
    title: 'الغرض',
    dataIndex: 'Purpose',
  },
  {
    key: 'Duration',
    title: 'المدة',
    dataIndex: 'Duration',
  },
  {
    key: 'Provider',
    title: 'المزود',
    dataIndex: 'Provider',
  },
  {
    key: 'Type',
    title: 'النوع',
    dataIndex: 'Type',
  },
]
const sx = {
  width: ['100%', '800px'],
  m: '0 auto',
  pt: '120px',
  pb: '50px',
  px: ['24px', 0],
  '.center': {
    textAlign: 'center',
  },
  '.bold': {
    fontWeight: 'bold',
  },
  '.dl': {
    py: '10px',
  },
  '.blue': {
    color: '#1890ff',
  },
  '.pl': {
    pl: '20px',
  },
  '.underline': {
    textDecoration: 'underline',
    fontSize: 18,
  },
}

export const UAEContent = () => {
  return (
    <Box sx={sx}>
      <h2 className='center'>Cookie Policy</h2>
      <div className='bold dl'>Last updated: [14/14/2024]</div>
      <div>
        This Cookie Policy outlines how iMile (collectively, <b>"iMile"</b>, <b>"we"</b>,{' '}
        <b>"us"</b>, and <b>"our"</b>) uses cookies and similar technologies when you visit our
        website <a href='https://www.imile.com/'>https://www.imile.com/</a> (<b>"Site"</b>). By
        accessing and using our Site, you consent to the use of cookies in accordance with this
        policy.
      </div>
      <div className='dl'>
        We take your privacy seriously and are committed to complying with all applicable data
        protection laws in the United Arab Emirates, including the UAE Data Protection Law{' '}
        <b>(Federal Decree-Law No. 45 of 2021)</b>, and other relevant regulations.
      </div>
      <div className='dl'>
        iMile Delivery Services L.L.C (referred to as <b>"iMile"</b> or <b>“iMile UAE”</b>) and its
        affiliates respect your privacy. We hereby develop this Cookie Policy (refer to as{' '}
        <b>“this Policy”</b>) to help you understand how we use cookies on this website (refer to as
        <b>"our Website"</b>), and to demonstrate our firm commitment to the privacy of your
        personal information. Please take a moment to read this Policy carefully and contact us if
        you have any questions.
      </div>
      <div className='dl'>
        More information on data protection you can find in our Privacy Policy under{' '}
        <a href='https://www.imile.com/privacy-policy'>https://www.imile.com/privacy-policy</a>.
      </div>
      <div className='bold dl underline'>What are cookies?</div>
      <div className='dl'>
        Cookies are small text files stored on your device (computer, smartphone, or tablet) when
        you visit a website. Cookies and similar technologies are very small text documents or
        pieces of code that often contain a unique identification code. Cookies are stored whereby a
        computer asks your computer or mobile device for permission to save this file on your
        computer or mobile device and gain access to information. Information collected through
        cookies and similar technologies may; remember your actions and preferences over a period of
        time.
      </div>
      <div className='dl'>
        Cookies can be either first-party (set by us) or third-party (set by external services like
        advertisers or analytics providers).
      </div>
      <div className='bold dl underline'>Why do we use cookies</div>
      <div className='dl'>
        Cookies can remember your sign-in credentials, so you don’t have to enter those credentials
        each time you log on to a service. The cookies ensure that we can see how our website is
        used, and that it functions efficiently and how we can improve it. Furthermore, depending on
        your preferences our own cookies may be used to present you with targeted advertisements
        that match your personal interests. Cookies also help enable and analyse security and site
        traffic in order to optimize user experience. iMile prides itself in customer experience,
        and these cookies help personalize content and offers, such as remembering your preferences.
        With the information obtained we are able to show you relevant advertisements based on your
        browsing behaviour and interests.
      </div>
      <div className='bold dl underline'>What type of cookies do we use?</div>
      <div className='dl'>iMile UAE uses the following types of cookies on this website:</div>
      <div className='dl blue'>Cookies by Party</div>
      <div className='bold dl'>First party cookies</div>
      <div className='dl'>
        These are necessary for the operation of our Site, such as enabling basic functionality and
        security features First-party cookies are stored on a website (domain) a user has visited
        directly. Publishers use these cookies to collect analytical data and optimize website
        functionality, like remembering the language preferences. First-party cookies are active by
        default and will not go away any time soon. This is because they are essential to perform
        key website features.
      </div>
      <div className='bold dl'>Third party cookies</div>
      <div className='dl'>
        We may use third-party cookies from partners who provide analytics, social media, and
        advertising services. Third party cookies are set by an organization other than the owner of
        the website you are visiting. For example, the website may use a third-party analytics
        provider that sets its own cookie to perform this service and these cookies track your
        activity over different sites. The website you are visiting may also contain content
        embedded from, for example, YouTube or Facebook, and these websites may set their own
        cookies.
      </div>
      <div className='bold dl underline'>Cookies by Duration</div>
      <div className='bold dl'>Session cookies</div>
      <div className='dl'>
        Session cookies are stored for the duration of a website visit and then automatically
        deleted when the browser is closed. They ensure, for example, that video and audio files can
        be played, your user input is temporarily stored during the input time and thus the user
        experience is improved.
      </div>
      <div className='bold dl'>Persistent cookies</div>
      <div className='dl'>
        Persistent cookies remain on your terminal device even after you close the browser. These
        cookies can, for example, store your user preferences, such as language settings, and
        analyse user behaviour on our website. The storage period of persistent cookies is
        determined individually per cookie. After expiry of the period, they are automatically
        deleted.
      </div>
      <div className='bold dl underline'>Cookies by Category</div>
      <div className='bold dl'>• Essential cookies</div>
      <div className='dl'>
        These cookies are essential for the website to function properly and cannot be switched off
        without affecting the use of the site. They are usually only set in response to actions you
        take that amount to a request for services, such as setting your privacy preferences,
        logging in or filling in forms. These cookies do not store any personal data.
      </div>
      <div className='dl'>
        Some of the following actions can be performed by using these cookies:
      </div>
      <div className='pl'>
        {[
          '•	Store articles in a shopping cart for online purchases',
          '•	Save your cookie preferences for this website',
          '•	Saving language preferences',
          '•	Log in to our portal. We need to check whether you are logged in.',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='bold dl'>• Performance cookies</div>
      <div className='dl'>
        These cookies are non-essential cookies, which are also specifically known as “measurement
        cookies” or “statistical cookies”. These cookies allow us to count visits and traffic
        sources so that we can measure and improve the performance of our website. They help us to
        know which pages are the most and least popular and how visitors move around the site. All
        information these cookies collect is aggregated and therefore anonymous.
      </div>
      <Table dataSource={data} columns={columns} pagination={false}></Table>
      <div className='bold dl'>• Functional cookies</div>
      <div className='dl'>
        These cookies enable the website to offer enhanced functionality and personalization. They
        may be set by us or by third party providers whose services we have added to our pages. The
        following functionalities may or may not be activated when you accept this category:
      </div>
      <div className='pl'>
        {[
          '•	Live chat services',
          '•	Watch online videos',
          '•	Social media sharing buttons',
          '•	Advertising / tracking cookies',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl'>
        These cookies are set by external advertising partners and are used for profiling and
        tracking data across multiple websites. If you accept these cookies, we may show our
        advertisements on other websites based on your user profile and preferences. These cookies
        may also save data about how many visitors have seen or clicked on our advertisements in
        order to optimize advertising campaigns.
      </div>
      <div className='bold dl underline'>
        Information on the individual cookies can be found below.
      </div>
      <div className='bold dl'>Managing cookies</div>
      <div className='dl'>
        If necessary, we will ask for your consent to the use of certain cookies described in this
        Policy when you access the website. If so, a banner will appear when you first visit the
        website informing you of the use of such cookies. If you reject the cookies, we will not set
        any cookies on your device other than those for which consent is not required or those that
        are strictly necessary (including a cookie to indicate that you do not accept the cookies to
        be set when you visit the website), and you can change your preferences later.
      </div>
      <div className='dl'>
        You can choose to opt out of all but the necessary cookies. You can also deactivate cookies
        by changing the settings of your website browser so that cookies are rejected. How to do
        this depends on the browser you are using. Most browsers allow you to change your cookie
        settings, which can usually be found in the 'Options' or 'Preferences' menu of your browser.
        Use the 'Help' option in your browser for more information. If you block the cookies, it is
        possible that you will not be able to enjoy all the technical features our website has to
        offer, and it may negatively affect your user experience.
      </div>
      <div className='dl'>
        The modification or withdrawal of consent for the placement of cookies can be made in
        Consent Settings. For more information, please refer to our consent settings policy.
      </div>
      <div className='bold dl underline'>Data Protection and Your Rights</div>
      <div className='dl'>
        In line with the UAE Data Protection Law, we take the privacy of your personal information
        seriously. If we process personal data via cookies, we ensure that it is done in compliance
        with applicable privacy laws. You have the following rights regarding your personal data:
      </div>
      <div className='pl'>
        {[
          '•	Access: You have the right to request access to the personal data we hold about you.',
          '•	Correction: You may request correction of inaccurate or incomplete personal data.',
          '•	Deletion: You may request deletion of personal data under certain circumstances.',
          '•	Objection: You may object to the processing of your data under specific conditions.',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl'>
        If you wish to exercise any of these rights, please contact us using the details provided
        below.
      </div>
      <div className='bold dl underline'>Sharing of Data</div>
      <div className='dl'>
        We may share data collected through cookies with trusted third-party service providers,
        including analytics and advertising partners. These third parties may process personal data
        on our behalf and in accordance with their own privacy policies. We ensure that any
        data-sharing arrangements comply with UAE law and that third parties are contractually
        obligated to safeguard your information.
      </div>
      <div className='bold dl underline'>Updates to This Cookie Policy</div>
      <div className='dl'>
        We may update this Cookie Policy from time to time to reflect changes in our practices or
        for legal, operational, or regulatory reasons. When we make changes, we will update the
        "Last Updated" date at the top of this policy. We encourage you to review this policy
        periodically to stay informed about how we use cookies.
      </div>
      <div className='bold dl'>Contact Us</div>
      <div className='dl'>
        If you have any questions, concerns or complaints in relation to this Cookie Policy, please
        feel free to contact us. You can reach us{' '}
        <span className='blue'>under DPO [at] imile.me</span>.{' '}
      </div>
    </Box>
  )
}

export const UAEarContent = () => {
  return (
    <Box
      sx={{
        ...sx,
        direction: 'rtl',
        h2: {
          textAlign: 'center',
        },
        '.pl': {
          pr: '20px',
        },
      }}
    >
      <h2>سياسة ملفات تعريف الارتباط</h2>
      <div className='dl'>آخر تحديث: [14/14/2024]</div>
      <div className='dl'>
        تحدد سياسة ملفات تعريف الارتباط هذه كيفية استخدام ايمايل (ويُشار إليها جماعياً بـ"ايمايل"،
        "نحن"، "لنا"، و"الخاصة بنا") لملفات تعريف الارتباط والتقنيات المشابهة عند زيارتك لموقعنا
        الإلكتروني <a href='https://www.imile.com/'>[https://www.imile.com/]</a>{' '}
        <a href='https://www.imile.com/'>(https://www.imile.com/)</a> ("الموقع"). من خلال الوصول إلى
        موقعنا واستخدامه، فإنك توافق على استخدام ملفات تعريف الارتباط وفقاً لهذه السياسة.
      </div>
      <div className='dl'>
        نحن نأخذ خصوصيتك على محمل الجد وملتزمون بالامتثال لجميع قوانين حماية البيانات المعمول بها في
        دولة الإمارات العربية المتحدة، بما في ذلك قانون حماية البيانات الإماراتي{' '}
        <b>(المرسوم بقانون اتحادي رقم 45 لسنة 2021)</b>، وغيرها من اللوائح ذات الصلة.
      </div>
      <div className='dl'>
        تلتزم ايمايل لخدمات التوصيل ذ.م.م (ويُشار إليها بـ"ايمايل" أو "ايمايل الإمارات") والشركات
        التابعة لها بحماية خصوصيتك. وبناءً على ذلك، قمنا بوضع سياسة ملفات تعريف الارتباط هذه
        ("السياسة") لمساعدتك على فهم كيفية استخدامنا لملفات تعريف الارتباط على هذا الموقع ("موقعنا
        الإلكتروني") وللتأكيد على التزامنا الثابت بحماية معلوماتك الشخصية. نرجو منك تخصيص بعض الوقت
        لقراءة هذه السياسة بعناية والتواصل معنا في حال كان لديك أي استفسارات.
      </div>
      <div className='dl'>
        يمكنك العثور على مزيد من المعلومات حول حماية البيانات في سياسة الخصوصية الخاصة بنا على
        الرابط التالي:{' '}
        <a href='https://www.imile.com/privacy-policy'>[https://www.imile.com/privacy-policy]</a>{' '}
        <a href='https://www.imile.com/privacy-policy'>(https://www.imile.com/privacy-policy)</a>.
      </div>
      <div className='dl bold underline'>ما هي ملفات تعريف الارتباط؟</div>
      <div className='dl'>
        ملفات تعريف الارتباط هي ملفات نصية صغيرة تُخزّن على جهازك (كمبيوتر، هاتف ذكي، أو جهاز لوحي)
        عند زيارتك لموقع إلكتروني. تتكون ملفات تعريف الارتباط والتقنيات المشابهة من مستندات نصية
        صغيرة جداً أو أكواد تحتوي غالباً على رمز تعريف فريد. يتم تخزين ملفات تعريف الارتباط عندما
        يطلب جهاز الكمبيوتر أو الجهاز المحمول الخاص بك الإذن لتخزين هذا الملف والوصول إلى المعلومات.
      </div>
      <div className='dl'>
        المعلومات التي تُجمع من خلال ملفات تعريف الارتباط والتقنيات المشابهة قد تساعد في تذكر
        إجراءاتك وتفضيلاتك لفترة زمنية. يمكن أن تكون ملفات تعريف الارتباط إما من الطرف الأول (تُحدد
        بواسطتنا) أو من طرف ثالث (تُحدد بواسطة خدمات خارجية مثل المعلنين أو موفري التحليلات).
      </div>
      <div className='dl bold underline'>لماذا نستخدم ملفات تعريف الارتباط؟</div>
      <div className='dl'>
        تساعد ملفات تعريف الارتباط في تذكر بيانات تسجيل الدخول الخاصة بك، بحيث لا تحتاج إلى إدخالها
        في كل مرة تقوم فيها بتسجيل الدخول إلى خدمة معينة. كما تضمن ملفات تعريف الارتباط أننا نستطيع
        معرفة كيفية استخدام موقعنا الإلكتروني، وأنه يعمل بكفاءة وكيف يمكن تحسينه.
      </div>
      <div className='dl'>
        علاوةً على ذلك، بناءً على تفضيلاتك، يمكن استخدام ملفات تعريف الارتباط الخاصة بنا لتقديم
        إعلانات موجهة تتناسب مع اهتماماتك الشخصية. كما تساعد ملفات تعريف الارتباط أيضاً في تمكين
        وتحليل الأمان وحركة المرور على الموقع بهدف تحسين تجربة المستخدم.
      </div>
      <div className='dl'>
        تفخر ايمايل بتقديم تجربة مميزة للعملاء، وتساهم هذه الملفات في تخصيص المحتوى والعروض، مثل
        تذكر تفضيلاتك. من خلال المعلومات التي نحصل عليها، يمكننا عرض إعلانات ذات صلة استناداً إلى
        سلوك تصفحك واهتماماتك.
      </div>
      <div className='dl bold underline'>ما هي أنواع ملفات تعريف الارتباط التي نستخدمها؟</div>
      <div className='dl'>
        تستخدم ايمايل الإمارات الأنواع التالية من ملفات تعريف الارتباط على هذا الموقع:
      </div>
      <div className='dl blue'>ملفات تعريف الارتباط حسب المصدر</div>
      <div className='dl bold'>ملفات تعريف الارتباط الخاصة بالطرف الأول</div>
      <div className='dl'>
        هذه الملفات ضرورية لتشغيل موقعنا، حيث تتيح الوظائف الأساسية وميزات الأمان. يتم تخزين ملفات
        تعريف الارتباط الخاصة بالطرف الأول على النطاق الذي زاره المستخدم مباشرة. يستخدم الناشرون هذه
        الملفات لجمع البيانات التحليلية وتحسين وظائف الموقع، مثل تذكر تفضيلات اللغة. تكون ملفات
        تعريف الارتباط الخاصة بالطرف الأول نشطة بشكل افتراضي ولن تختفي قريبًا لأنها أساسية لتنفيذ
        الميزات الرئيسية للموقع.
      </div>
      <div className='dl bold'>ملفات تعريف الارتباط الخاصة بالطرف الثالث</div>
      <div className='dl'>
        قد نستخدم ملفات تعريف الارتباط الخاصة بالطرف الثالث من شركاء يقدمون خدمات التحليلات ووسائل
        التواصل الاجتماعي والإعلانات. يتم تعيين ملفات تعريف الارتباط الخاصة بالطرف الثالث بواسطة
        منظمة غير مالك الموقع الذي تزوره. على سبيل المثال، قد يستخدم الموقع موفر تحليلات تابعًا لطرف
        ثالث يقوم بتعيين ملف تعريف ارتباط خاص به لتقديم هذه الخدمة، حيث تتبع هذه الملفات نشاطك عبر
        مواقع مختلفة. قد يحتوي الموقع الذي تزوره أيضًا على محتوى مضمّن من مواقع أخرى، مثل يوتيوب أو
        فيس بوك، وقد تقوم هذه المواقع بتعيين ملفات تعريف ارتباط خاصة بها.
      </div>
      <div className='dl bold underline'>ملفات تعريف الارتباط حسب المدة الزمنية</div>
      <div className='dl bold'>ملفات تعريف الارتباط للجلسة</div>
      <div className='dl'>
        يتم تخزين ملفات تعريف الارتباط للجلسة طوال مدة زيارة الموقع ثم يتم حذفها تلقائيًا عند إغلاق
        المتصفح. تضمن هذه الملفات، على سبيل المثال، إمكانية تشغيل ملفات الفيديو والصوت، وتخزين
        مدخلات المستخدم مؤقتًا خلال وقت الإدخال مما يحسن تجربة المستخدم.
      </div>
      <div className='dl bold'>ملفات تعريف الارتباط الدائمة</div>
      <div className='dl'>
        تظل ملفات تعريف الارتباط الدائمة على جهازك حتى بعد إغلاق المتصفح. يمكن لهذه الملفات تخزين
        تفضيلات المستخدم، مثل إعدادات اللغة، وتحليل سلوك المستخدم على موقعنا. يتم تحديد فترة تخزين
        ملفات تعريف الارتباط الدائمة بشكل فردي لكل ملف. بعد انتهاء الفترة، يتم حذفها تلقائيًا.
      </div>
      <div className='dl bold underline'>ملفات تعريف الارتباط حسب الفئة</div>
      <div className='dl'>• ملفات تعريف الارتباط الضرورية</div>
      <div className='dl'>
        تُعَدّ هذه الملفات ضرورية لضمان عمل الموقع بشكل صحيح، ولا يمكن تعطيلها دون التأثير على
        استخدام الموقع. وعادةً ما يتم تفعيلها استجابةً لإجراءات تقوم بها تُعَدّ طلبًا للحصول على
        خدمات، مثل ضبط تفضيلات الخصوصية، أو تسجيل الدخول، أو تعبئة النماذج. ولا تقوم هذه الملفات
        بتخزين أي بيانات شخصية.
      </div>
      <div className='dl'>بعض الإجراءات التالية يمكن تنفيذها باستخدام هذه الملفات:</div>
      <div className='pl'>
        {[
          '•	تخزين المقالات في عربة التسوق عند إجراء عمليات شراء عبر الإنترنت.',
          '•	حفظ تفضيلات ملفات تعريف الارتباط الخاصة بهذا الموقع.',
          '•	حفظ تفضيلات اللغة.',
          '•	تسجيل الدخول إلى بوابتنا. نحن بحاجة إلى التحقق مما إذا كنت مسجل الدخول.',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl bold'>• ملفات تعريف الارتباط الخاصة بالأداء</div>
      <div className='dl'>
        تُعدّ هذه الملفات غير ضرورية، ويُطلق عليها أيضًا اسم "ملفات تعريف الارتباط لقياس الأداء" أو
        "ملفات تعريف الارتباط الإحصائية". تتيح لنا هذه الملفات عدّ الزيارات ومصادر حركة المرور حتى
        نتمكن من قياس وتحسين أداء موقعنا الإلكتروني. تساعدنا في معرفة الصفحات الأكثر والأقل شعبية
        وكيفية تنقل الزوار عبر الموقع. جميع المعلومات التي تجمعها هذه الملفات تكون مجمّعة وبالتالي
        مجهولة المصدر.
      </div>
      <Table dataSource={arData} columns={arColumns} pagination={false}></Table>
      <div className='dl bold'>• ملفات تعريف الارتباط الوظيفية</div>
      <div className='dl'>
        تمكن هذه الملفات الموقع من تقديم وظائف مُعزَّزة وتخصيصات للمستخدم. قد يتم تعيينها من قبلنا
        أو من قبل مزودي الطرف الثالث الذين أضفنا خدماتهم إلى صفحاتنا. قد يتم تفعيل الوظائف التالية
        أو عدم تفعيلها عندما تقبل هذه الفئة:
      </div>
      <div className='pl'>
        {[
          '•	خدمات الدردشة المباشرة ',
          '•	مشاهدة مقاطع الفيديو عبر الإنترنت ',
          '•	أزرار مشاركة وسائل التواصل الاجتماعي',
          '•	ملفات تعريف الارتباط الإعلانية / ملفات تعريف الارتباط للتتبع',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl'>
        يتم تعيين هذه الملفات من قبل شركاء الإعلان الخارجيين وتستخدم في إنشاء ملفات تعريف وتتبع
        البيانات عبر عدة مواقع. إذا قبلت هذه الملفات، قد نعرض إعلاناتنا على مواقع أخرى بناءً على
        ملفك الشخصي وتفضيلاتك. قد تحفظ هذه الملفات أيضًا بيانات حول عدد الزوار الذين شاهدوا أو نقروا
        على إعلاناتنا من أجل تحسين الحملات الإعلانية.
      </div>
      <div className='dl bold underline'>
        معلومات حول ملفات تعريف الارتباط الفردية يمكن العثور عليها أدناه.
      </div>
      <div className='dl bold'>إدارة ملفات تعريف الارتباط</div>
      <div className='dl'>
        إذا لزم الأمر، سنطلب موافقتك لاستخدام بعض ملفات تعريف الارتباط الموضحة في هذه السياسة عند
        وصولك إلى الموقع. إذا كان الأمر كذلك، سيظهر لك شريط تنبيه عند زيارتك الأولى للموقع يُعلمك
        باستخدام مثل هذه الملفات. إذا رفضت ملفات تعريف الارتباط، فلن نقوم بتعيين أي ملفات على جهازك
        بخلاف تلك التي لا تتطلب موافقة أو التي تعتبر ضرورية تمامًا (بما في ذلك ملف تعريف ارتباط يشير
        إلى أنك لا تقبل تعيين ملفات تعريف الارتباط عند زيارتك للموقع)، ويمكنك تغيير تفضيلاتك لاحقًا.
      </div>
      <div className='dl'>
        يمكنك اختيار عدم قبول جميع الملفات باستثناء الملفات الضرورية. يمكنك أيضًا تعطيل ملفات تعريف
        الارتباط عن طريق تغيير إعدادات متصفح الموقع بحيث يتم رفض ملفات تعريف الارتباط. كيفية القيام
        بذلك تعتمد على المتصفح الذي تستخدمه. يسمح معظم المتصفحات بتغيير إعدادات ملفات تعريف
        الارتباط، والتي يمكنك عادةً العثور عليها في قائمة "الخيارات" أو "التفضيلات" في متصفحك.
        استخدم خيار "المساعدة" في متصفحك للحصول على مزيد من المعلومات. إذا قمت بحظر ملفات تعريف
        الارتباط، فمن الممكن أن لا تتمكن من الاستمتاع بجميع الميزات التقنية التي يقدمها موقعنا، وقد
        يؤثر ذلك سلبًا على تجربتك كـ مستخدم.
      </div>
      <div className='dl'>
        يمكنك تعديل أو سحب موافقتك على وضع ملفات تعريف الارتباط من خلال إعدادات الموافقة. للحصول على
        مزيد من المعلومات، يرجى الرجوع إلى سياسة إعدادات الموافقة لدينا.
      </div>
      <div className='dl bold underline'>حماية البيانات وحقوقك</div>
      <div className='dl'>
        تماشيًا مع قانون حماية البيانات في الإمارات العربية المتحدة، نولي خصوصية معلوماتك الشخصية
        اهتمامًا بالغًا. إذا قمنا بمعالجة البيانات الشخصية عبر ملفات تعريف الارتباط، فإننا نحرص على
        أن يتم ذلك بما يتوافق مع القوانين المعمول بها المتعلقة بالخصوصية. لديك الحقوق التالية فيما
        يتعلق ببياناتك الشخصية:
      </div>
      <div className='pl'>
        {[
          '•	الوصول: يحق لك طلب الوصول إلى البيانات الشخصية التي نحتفظ بها عنك.',
          '•	التصحيح: يمكنك طلب تصحيح البيانات الشخصية غير الدقيقة أو غير المكتملة.',
          '•	الحذف: يمكنك طلب حذف البيانات الشخصية في ظل ظروف معينة.',
          '•	الاعتراض: يمكنك الاعتراض على معالجة بياناتك تحت شروط معينة.',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl'>
        إذا كنت ترغب في ممارسة أي من هذه الحقوق، يرجى الاتصال بنا باستخدام التفاصيل المدرجة أدناه.
      </div>
      <div className='dl bold underline'>مشاركة البيانات</div>
      <div className='dl'>
        قد نشارك البيانات التي تم جمعها من خلال ملفات تعريف الارتباط مع مقدمي خدمات طرف ثالث
        موثوقين، بما في ذلك شركاء التحليلات والإعلانات. قد تقوم هذه الأطراف الثالثة بمعالجة البيانات
        الشخصية نيابة عنا ووفقًا لسياسات الخصوصية الخاصة بها. نحن نحرص على أن تتوافق أي ترتيبات
        لمشاركة البيانات مع قانون الإمارات العربية المتحدة وأن تكون الأطراف الثالثة ملزمة بموجب عقد
        بحماية معلوماتك.
      </div>
      <div className='dl bold underline'>التحديثات على سياسة ملفات تعريف الارتباط هذه</div>
      <div className='dl'>
        قد نقوم بتحديث سياسة ملفات تعريف الارتباط هذه من وقت لآخر لتعكس التغييرات في ممارساتنا أو
        لأسباب قانونية أو تشغيلية أو تنظيمية. عندما نقوم بإجراء تغييرات، سنقوم بتحديث تاريخ "آخر
        تحديث" في أعلى هذه السياسة. نوصي بمراجعة هذه السياسة بشكل دوري للبقاء على اطلاع حول كيفية
        استخدامنا لملفات تعريف الارتباط.
      </div>
      <div className='dl'>اتصل بنا</div>
      <div className='dl'>
        إذا كان لديك أي أسئلة أو مخاوف أو شكاوى تتعلق بسياسة ملفات تعريف الارتباط هذه، لا تتردد في
        الاتصال بنا. يمكنك الوصول إلينا عبر عنوان البريد الإلكتروني الخاص بمسؤول حماية البيانات
        لشركة ايمايل.
      </div>
    </Box>
  )
}
